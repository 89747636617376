import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export interface DisplayConfig {
  showAllLocations: boolean
  enabledLocations?: string[]
}

export type DisplaySettingsParams = {
  displayConfig: SettingsParamType.Object
}

export const displaySettingsParams = createSettingsParams<DisplaySettingsParams>({
  displayConfig: {
    type: SettingsParamType.Object,
    getDefaultValue: (): DisplayConfig => ({showAllLocations: true}),
  },
})
