import React, {FC} from 'react'
import {useTranslation, WidgetProps} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/cssVars'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {withReservationAddOnStorageProvider} from '../storage'
import {stylesParams} from '../stylesParams'
import {settingsParams} from '../settingsParams'
import {FormLayout, TextAlignment} from '../../../types/createStylesParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {LayoutSize} from '../../../components-shared/LayoutSizeProvider/types'
import {withLayoutSizeProvider} from '../../../components-shared/LayoutSizeProvider'
import {NotAcceptingBanner} from '../../../components-shared/NotAcceptingModal/NotAcceptingBanner'

import {LAYOUT_BREAKPOINTS_START} from './constants'
import {useHooks} from './useHooks'
import {classes, st} from './Widget.st.css'
import {Form} from './components/Form'

const Widget: FC<WidgetProps> = () => {
  const styles = useStyles()
  const settings = useSettings()
  const {
    shouldShowNoLocations,
    enabledReservationLocations,
    isAllLocationsFullyManual,
    layoutSize,
    containerWidth,
  } = useHooks()
  const {t} = useTranslation()

  const showTitle = settings.get(settingsParams.showTitle)
  const showDescription = settings.get(settingsParams.showDescription)
  const textAlignment = settings.get(settingsParams.textAlignment)
  const titleText = settings.get(
    isAllLocationsFullyManual ? settingsParams.titleRequestText : settingsParams.titleText,
  )
  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const descriptionText = settings.get(
    isAllLocationsFullyManual
      ? settingsParams.descriptionRequestsText
      : settingsParams.descriptionText,
  )
  const descriptionTag =
    styles.get(stylesParams.subtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.subtitleFont).htmlTag

  const textAlignmentClass = TextAlignmentToClass[textAlignment]

  const formLayout = settings.get(settingsParams.formLayout)

  const formLayoutClass =
    formLayout === FormLayout.Packed || layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S
      ? classes.packed
      : classes.strip

  if (shouldShowNoLocations) {
    return (
      <RtlProvider>
        <div className={st(classes.root, {[layoutSize]: true})}>
          <NotAcceptingBanner layoutSize={layoutSize} className={classes.notAcceptingBanner} />
        </div>
      </RtlProvider>
    )
  }

  return (
    <RtlProvider>
      <div
        aria-label={t('uou-reservations.add-on.aria-label')}
        className={st(classes.root, {[layoutSize]: true}, formLayoutClass)}
      >
        {showTitle ? (
          <Text tagName={titleTag} className={st(classes.title, textAlignmentClass)}>
            {titleText}
          </Text>
        ) : null}

        {showDescription ? (
          <Text tagName={descriptionTag} className={st(classes.subtitle, textAlignmentClass)}>
            {descriptionText}
          </Text>
        ) : null}

        <Form
          enabledReservationLocations={enabledReservationLocations}
          layoutSize={layoutSize}
          containerWidth={containerWidth}
        />
      </div>
    </RtlProvider>
  )
}

const TextAlignmentToClass: Record<TextAlignment, string> = {
  [TextAlignment.Left]: classes.textLeft,
  [TextAlignment.Center]: classes.textCenter,
  [TextAlignment.Right]: classes.textRight,
}

export default withLayoutSizeProvider(
  withReservationAddOnStorageProvider(Widget),
  LAYOUT_BREAKPOINTS_START,
  classes.rootWrapper,
)
